import React from "react";
import { Route /*,Redirect*/ } from "react-router";
import Loadable from "react-loadable";
import Loading from "../ui-molecules/Loading";
const UserHome = Loadable({
  loader: () => import("../ui-pages/UserHome"),
  loading: Loading,
});

const Landing = Loadable({
  loader: () => import("../ui-pages/Landing"),
  loading: Loading
});

const MainRoutes = () => {
  return (
    <div>
      <Route exact path="/" component={Landing} />
      <Route path="/user-home" component={UserHome} />
      {/*<Redirect to="/user-home/payment/12382502"/>*/}
    </div>
  );
};

export default MainRoutes;
