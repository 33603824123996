import "./App.css";

import { getQueryArg, headings, mapDispatchToProps } from "./ui-utils/commons";

// import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Loader from "./ui-containers/Loader";
import MainRoutes from "./ui-routes/MainRoutes";
import Menu from "./ui-molecules/Drawer";
import React from "react";
import Snackbar from "./ui-containers/SnackBar";
import Typography from "@material-ui/core/Typography";
// import apiConfig from "./ui-utils/apiConfig";
import { connect } from "react-redux";
import { httpRequest } from "./ui-utils/api";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

// import {analytics} from "./ui-config/firebase";

class App extends React.Component {
  state = {
    appEnable: true,
  };
  componentDidMount = async () => {
    // const { setState } = this;
    const { setAppData, history } = this.props;
    setAppData("spinner", true);
    try {
      // if(apiConfig.ENV==="prod"){
      //   // const response =
      //   await window.microapps.getPhoneNumber();
      // }
      // const decoded = JSON.parse(atob(response.split(".")[1]));
      // if (decoded) {
      setAppData("spinner", false);
      setAppData("showWebSite", true);
      let requestBody = {
        client_secret: "dmVyaXNtYXJ0",
      };
      let apiResponse = await httpRequest({
        endPoint: "/api/v1/login/",
        method: "post",
        requestBody,
      });
      if (apiResponse.sucess === false) {
        setAppData("user-info", apiResponse.message);
      } else {
        localStorage.setItem("user-info", JSON.stringify(apiResponse));
      }
      let status = getQueryArg("param1"),
        customerId = getQueryArg("param2"),
        timestamp = getQueryArg("param3"),
        orderId = getQueryArg("param4"),
        flow = getQueryArg("param5");
      if (status !== "recharge" && customerId && timestamp && orderId) {
        let route = `/user-home/tracking/${status}/${customerId}/${timestamp}`;
        let routePathAppend = {
          id: route,
          heading: "Tracking Details",
        };
        headings.splice(8, 1, routePathAppend);
        history.push(route);
      } else if (status === "recharge") {
        let route = `/user-home/recharge-fastag-details/${customerId}`;
        history.push(route);
      } else if (flow === "landing") {
        let route = `/user-home`;
        history.push(route);
      } else if (flow === "recharge") {
        let route = `/user-home/recharge-fastag`;
        history.push(route);
      }
      window.ga("send", "event", "page-visit", "APP_LAUNCH");
      // analytics.logEvent("APP_LAUNCH");
      // }
      // var requestOptions = {
      //   method: 'GET',
      //   redirect: 'follow'
      // };

      // fetch("https://0irlfzn3sh.execute-api.us-east-2.amazonaws.com/default/fastag_switch", requestOptions)
      //   .then(response => response.text())
      //   .then(result => {
      //     console.log(result)
      //     this.setState({
      //       appEnable: result.appEnable
      //     })
      //   })
      //   .catch(error => console.log('error', error));
    } catch (e) {
      // setAppData("spinner", false);
      // window.ga("send", "event", "page-visit", "APP_OPENED_OUTSIDE_GPAY");
      // setAppData("showWebSite", false);
      // setAppData("showWebForbiddenText", "Forbidden");
    }
  };

  render() {
    const { spinner, showWebSite, showWebForbiddenText } = this.props;
    const { appEnable = true } = this.state;
    console.log("appEnable", appEnable);
    if (showWebSite && appEnable) {
      return (
        <div>
          <Hidden only={["xs"]}>
            <Grid
              container
              alignItems="center"
              justify="center"
              classes={{ root: "custom-fullhieght" }}
            >
              <Typography variant="h5">
                No web view, please open in mobile or tablet
              </Typography>
            </Grid>
          </Hidden>
          <Hidden only={["sm", "md", "lg", "xl"]}>
            <div
              style={{
                position: "absolute",
                top: 4,
                right: 0,
              }}
            >
              <Menu />
            </div>
            <MainRoutes />
          </Hidden>
          <Snackbar />
          {spinner && (
            <div className="custom-spinner">
              <Loader />
              {/* <CircularProgress /> */}
            </div>
          )}
        </div>
      );
    } else if (!appEnable) {
      return <div>App is under maintainance please try after some time</div>;
    } else {
      return <div>{showWebForbiddenText}</div>;
    }
  }
}

const mapStateToProps = ({ screenConfiguration }) => {
  const { preparedFinalObject = {} } = screenConfiguration;
  const {
    spinner,
    selectedLanguage = "en",
    showWebSite = false,
    showWebForbiddenText = "",
  } = preparedFinalObject;
  return { spinner, selectedLanguage, showWebSite, showWebForbiddenText };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(App)));
