import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ openModal, closeModal }) {
  const classes = useStyles();

  const handleClose = () => {
    closeModal(false);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={openModal}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            Privacy Commitment
          </Toolbar>
        </AppBar>
        <div style={{ padding: "4px" }}>
          <Typography paragraph>
            In the course of using this website, mobile applications or its
            custom extensions or availing the products and services vide the
            online application forms and questionnaires, ICICI Bank and its
            Affiliates may become privy to the personal information of its
            customers, including information that is of a confidential nature.
          </Typography>
          <Typography paragraph>
            ICICI Bank is strongly committed to protecting the privacy of its
            customers and has taken all necessary and reasonable measures to
            protect the confidentiality of the customer information and its
            transmission through the world wide web and it shall not be held
            liable for disclosure of the confidential information when in
            accordance with this privacy Commitment or in terms of the
            agreements, if any, with the Customers.
          </Typography>
          <Typography paragraph>
            ICICI Bank endeavours to safeguard and ensure the security of the
            information provided by the Customer. ICICI Bank uses 128-bit
            encryption, for the transmission of the information, which is
            currently the permitted level of encryption in India. When the
            information provided by the Customers is not transmitted through
            this encryption, the Customers' system (if configured accordingly)
            will display an appropriate message ensuring the best level of
            secrecy for the Customer's information.
          </Typography>
          <Typography paragraph>
            The Customer would be required to cooperate with ICICI Bank in order
            to ensure the security of the information, and it is recommended
            that the Customers necessarily choose their passwords carefully such
            that no unauthorised access is made by a third party. To make the
            password complex and difficult for others to guess, the Customers
            should use combination of alphabets, numbers and special characters
            (like !, @, #, $ etc.). The Customers should undertake not to
            disclose their password to anyone or keep any written or other
            record of the password such that a third party could access it.
          </Typography>
          <Typography paragraph>
            ICICI Bank undertakes not to disclose the information provided by
            the Customers to any person, unless such action is necessary to:
            <ul>
              <li>
                Conform to legal requirements or comply with legal process;{" "}
              </li>
              <li>
                Protect and defend ICICI Bank's or its Affiliates' rights,
                interests or property;
              </li>
              <li>
                Enforce the terms and conditions of the products or services; or{" "}
              </li>
              <li>
                Act to protect the interests of ICICI Bank, its Affiliates, or
                its
              </li>
            </ul>
            members, constituents or of other persons. The Customers shall not
            disclose to any other person, in any manner whatsoever, any
            information relating to ICICI Bank or its Affiliates of a
            confidential nature obtained in the course of availing the services
            through the website. Failure to comply with this obligation shall be
            deemed a serious breach of the terms herein and shall entitle ICICI
            Bank or its Affiliates to terminate the services, without prejudice
            to any damages, to which the customer may be entitled otherwise.
          </Typography>
          <Typography paragraph>
            ICICI Bank will limit the collection and use of customer information
            only on a need-to-know basis to deliver better service to the
            customers. ICICI Bank may use and share the information provided by
            the Customers with its Affiliates and selected and credible third
            parties (subject to suitable confidentiality obligations) for
            providing services and any service-related activities such as
            collecting subscription fees for such services, and notifying or
            contacting the Customers regarding any problem with, or the
            expiration of, such services. In this regard, it may be necessary to
            disclose the customer information to one or more agents and
            contractors of ICICI Bank and their sub-contractors, but such
            agents, contractors, and sub-contractors will be required to agree
            to use the information obtained from ICICI Bank only for these
            purposes. We may share your contact details with our trusted third
            party partners, who are subject to appropriate confidentiality
            obligations, to enable us to provide you information about our
            products and services.
          </Typography>
          <Typography paragraph>
            Cookie policy: ICICI Bank website uses Google Analytics, a web
            analytics service provided by Google, Inc. ("Google"). Google
            Analytics uses cookies that are text files containing small amounts
            of information (this does not include personal sensitive
            information) which are downloaded to your device when you visit a
            website in order to provide a personalised browsing experience.
            Cookies do lots of different jobs, like allowing users to navigate
            between pages efficiently, remembering their preferences, and
            generally improving their browsing experience. These cookies collect
            information analytics about how users use a website, for instance
            often visited pages. All information collected by third party
            cookies is aggregated and therefore anonymous. By using our website
            user/s agree that these types of cookies can be placed on his/her
            device. User/s is free to disable/delete these cookies by changing
            his/her web browser settings. ICICI Bank is not responsible for
            cookies placed in the device of user/s by any other website and
            information collected thereto.
          </Typography>
          <Typography paragraph>
            The Customer authorises ICICI Bank to exchange, share, part with all
            information related to the details and transaction history of the
            Customers to its Affiliates / banks / financial institutions /
            credit bureaus / agencies/participation in any telecommunication or
            electronic clearing network as may be required by law, customary
            practice, credit reporting, statistical analysis and credit scoring,
            verification or risk management and shall not hold ICICI Bank liable
            for use or disclosure of this information.
          </Typography>
        </div>
      </Dialog>
    </div>
  );
}
